.MuiSkeleton-root {
  border-radius: 8px;
}

.skeleton-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.skeleton-group {
  gap: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skeleton-f-d-column {
  flex-direction: column;
}

@media screen and (max-width: 1280px) {
  .skeleton-group {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .skeleton-width-100 {
    width: 100% !important;
  }
}
