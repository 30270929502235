@media (max-width: 780px){
    .rccs__card--front {
        width: 100% !important;
    }
    .rccs__card{
        width: 100%;
        height: 170px;
    }
    .rccs{
        width: 100%;
    }
}

@media (min-width: 781px){
    .rccs__card--front {
        width: 100% !important;
    }
    .rccs__card{
        width: 100%;
        height: 240px;
    }
    .rccs{
        width: 100%;
    }
}