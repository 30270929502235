@import "~@77sol/styles/dist/index.css";

.introjs-helperNumberLayer {
    font-family:var(--font-family) !important;
    font-size: 14px;
    text-shadow: none;
    width: 22px;
    height: 22px;
    line-height: 22px;
    border: 0.5px solid #063EF9;
    border-radius: 50%;
    background: #063EF9;
  }

  .innerTextHelper {
    font-family:var(--font-family) !important;
    line-height: 22px;
    letter-spacing: 0.25px;
    font-weight: normal
  }

  .introjs-tooltip-title {
    font-family:var(--font-family) !important;
    font-weight: bold;
    color: #063EF9;
    font-size: 17px;
  }

  .introjs-tooltip {
    font-family:var(--font-family) !important;
    border-radius: 8px;
    max-width: 350px;
    min-width: 350px;
    margin-top: 16px;
  }

  .introjs-bullets ul li a.active {
    background-color: #063EF9;
  }

  .introjs-tooltiptext {
    font-family:var(--font-family) !important;
    font-size: 14px;
  }

  .introjs-tooltip-header {
    font-family:var(--font-family) !important;
    display: flex;
  }

  .introjs-progressbar {
    background-color: #063EF9;
  }
  
  .introjs-helperLayer {
    font-family:var(--font-family) !important;
    border: none;
    box-shadow: rgb(33 33 33 / 80%) 0px 0px 0px 0px, rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important;
    border-radius: 8px;
    margin-top: -8px;
    margin-left: -24px;
    padding-right: 24px;
    padding-bottom: 16px;
  }
  
  .introjs-tooltip {
    font-family:var(--font-family) !important;
    letter-spacing: .1em;
    color: #262338;
    border-radius: 8px;
  }
  
  .introjs-button {
    font-family:var(--font-family) !important;
    padding: 0.6em 0.8em;
    text-shadow: none;
    font-weight: bold;
    color: #063EF9;
    border-color: #063EF9;
    background: #fff;
    background-image: none;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-transition: background .3s, border .3s;
       -moz-transition: background .3s, border .3s;
        -ms-transition: background .3s, border .3s;
         -o-transition: background .3s, border .3s;
            transition: background .3s, border .3s;
  }
  
  .introjs-prevbutton {
    font-family:var(--font-family) !important;
    border-radius: 8px;
  }
  
  .introjs-nextbutton {
    font-family:var(--font-family) !important;
    border-radius: 8px;
  }
  
  .introjs-button:hover, .introjs-button:focus {
    font-family:var(--font-family) !important;
    background: #063EF9;
    color: #fff;
    box-shadow: none;
    border-color: #063EF9;
    text-decoration: none;
  }