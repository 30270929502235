.input-file {
  div.MuiFormControl-root > div.MuiInputBase-root > button {
    color: white !important;
  }
}

.input-upload-file-with-file {
  div.MuiFormControl-root > div.MuiInputBase-root > button {
    color: rgb(255, 0, 0) !important;
  }
}