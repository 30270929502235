.form-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 25px 10px;

  .g-xs-12 {
    grid-column: span 12;
  }

  .g-xs-8 {
    grid-column: span 8;
  }

  .g-xs-4 {
    grid-column: span 4;
  }

  @media (min-width: 1280px) {
    .g-md-9 {
      grid-column: span 9;
    }
    .g-md-8 {
      grid-column: span 8;
    }
    .g-md-7 {
      grid-column: span 7;
    }

    .g-md-6 {
      grid-column: span 6;
    }
    
    .g-md-5 {
      grid-column: span 5;
    }

    .g-md-4 {
      grid-column: span 4;
    }

    .g-md-3 {
      grid-column: span 3;
    }

    .g-md-2 {
      grid-column: span 2;
    }

    .g-md-1 {
      grid-column: span 1;
    }
  }
}


